const dureeSlide = 9000;
let nombreConseillersAffiches = 3;
let elementsHasSnapSliper = ['[data-slider="dm"]', '[data-slider="dg"]']

"use strict";

var home = {
	init(settings) {
		/// Flèche sroll bottom
		//this.jumpTonextSection('[data-js="scrollNext"]');

		/// Dernières minutes et départs garantis
		for (var i = 0; i < elementsHasSnapSliper.length; i++) {
			this.snap(elementsHasSnapSliper[i]);
		}
	},

	jumpTonextSection: function (id) {
		const btn = document.querySelector(id);
		btn.addEventListener('click', function (el) {
			let parent = el.target.parentNode,
				nextSection = parent.nextElementSibling,
				posYNextSection = nextSection.getBoundingClientRect().top;
			window.scroll(0, posYNextSection - 28); // margin top section
		});
	},

	/// SNAP SLIDER [Dernières minutes et départs garantis]
	snap(selector) {
		let self = this,
			elementSnap = document.querySelector(`${selector} [data-snap]`),
			items = elementSnap.querySelectorAll('li');


		if (helpers.elementExist(`${selector} .glide__bullet`)) {
			document.querySelectorAll(`${selector} .glide__bullet`).forEach(el => {
				/// On ajoute la classe 'is-active' sur la première bullet
				/// Au click sur une bullet, on scroll jusqu'à l'item correspondant
				el.dataset.glideDir === "0" && el.classList.add('is-active');
				el.addEventListener('click', function (bullet) {
					let index = parseInt(bullet.target.dataset.glideDir);
					items[index].scrollIntoView({
						behavior: 'smooth',
						block: 'nearest'
					});
				})
			});
			/// Event scroll
			/// Lors d'un event scroll sur `elementSnap`, on active la bullet correspondante
			elementSnap.addEventListener('scroll', function (e) {
				self._scrollHandlerSnap(e.target, selector, items)
			});
		}

		/// Event click arrow
		if (helpers.elementExist(`${selector} .glide__arrow`)) {
			document.querySelectorAll(`${selector} .glide__arrow`).forEach(el => {
				el.addEventListener('click', function (btn) {
					let width = document.querySelector(selector).getBoundingClientRect().width,
						dir = btn.target.dataset.glideDir === ">" ? width : -width;

					elementSnap.scrollBy({
						top: 0,
						left: dir,
						behavior: 'smooth'
					});
				})
			})
		}
	},
	_scrollHandlerSnap(target, selector, items) {
		let atSnappingPoint = target.scrollLeft % target.offsetWidth === 0,
			timeOut = atSnappingPoint ? 0 : 10,
			arrayOfBullets = document.querySelectorAll(`${selector} .glide__bullet`);

		clearTimeout(target.scrollTimeout); // on remet le timeout à 0

		target.scrollTimeout = setTimeout(function () {
			//!timeOut ? console.log('Scroller snapped!') : console.log('User stopped scrolling.');
			let index = 0;
			items.forEach(el => {
				el.getBoundingClientRect().x === target.getBoundingClientRect().x ? arrayOfBullets[index].classList.add('is-active') : arrayOfBullets[index].classList.remove('is-active');
				index++;
			})
		}, timeOut);
	},
	/// #SNAP SLIDER


	_animateCSS(element, animationName, callback) {
		const node = element
		node.classList.add('animated', animationName)

		function handleAnimationEnd() {
			node.classList.remove('animated', animationName)
			node.removeEventListener('animationend', handleAnimationEnd)

			if (typeof callback === 'function') callback()
		}

		node.addEventListener('animationend', handleAnimationEnd)
	},
	_animSlider: function () {
		let nomPays = document.querySelectorAll('.slider--pays');
		let titre = document.querySelectorAll('.slider--titre');
		let btn = document.querySelectorAll('.slider--btn');

		for (var i = 0; i < nomPays.length; i++) {
			this._animateCSS(nomPays[i], 'fadeInDown');
		}
		for (var i = 0; i < titre.length; i++) {
			this._animateCSS(titre[i], 'fadeInDown');
		}
		for (var i = 0; i < btn.length; i++) {
			this._animateCSS(btn[i], 'fadeInDown');
		}
	},
	_loadImgSlide(container, windowWidth, breakpoint = 1024) {
		let li = document.querySelectorAll(`${container} .glide__slide`);
		for (var i = li.length - 1; i >= 0; i--) {
			let bg = li[i].getAttribute('data-img-desktop');
			if (windowWidth < breakpoint) {
				bg = li[i].getAttribute('data-img-mobile');
			}
			li[i].style.backgroundImage = 'url(' + bg + ')';

			// if (Boolean(li[i].dataset.imageCache)) {
			// 	let pathImg = li[i].getAttribute('data-img-desktop');
			// 	if (windowWidth < breakpoint) {
			// 		li[i].getAttribute('data-img-mobile');
			// 	}
			// 	// On met en cache le chemin de la première image
			// 	helpers.setCookie('heroImg', pathImg, 1)
			// }
		}
	},
	slider: function (windowWidth, Glide) {
		let settings = {} || settings;

		/// Carousel
		settings.carousel = document.getElementById('carouselHero') === null ? false : true;

		if (settings.carousel) {
			if (parseInt(document.getElementById('carouselHero').dataset.count) > 1) {
				var carouselHome = new Glide('#carouselHero', {
						type: 'carousel',
						autoplay: dureeSlide,
						hoverpause: false,
						gap: 0
					})
					.on(['mount.before', 'run'], function () {
						home._animSlider();

						if (typeof document.body.animate === 'function') {
							document.querySelector('.progress--bar').animate([
								// keyframes
								{
									width: '0'
								}, {
									width: '100%'
								}
							], {
								// timing options
								duration: dureeSlide,
								iterations: Infinity
							});
						}

						home._loadImgSlide('#carouselHero', windowWidth);

					})
					.mount();
			} else {
				home._animSlider();
				home._loadImgSlide('#carouselHero', windowWidth);
			}
		}

		// Coup de coeur
		if (helpers.elementExist('[data-slider="coupdecoeur"]')) {
			var coupDeCoeur = new Glide('[data-slider="coupdecoeur"]', {
					type: 'slider',
					autoplay: dureeSlide * 50,
					hoverpause: true,
					gap: 0
				})
				.on(['mount.before', 'run'], function () {
					home._loadImgSlide('[data-slider="coupdecoeur"]', windowWidth, 810);
				})
				.mount();
		} else {
			if (document.querySelector('.section--coupdecoeur') !== null) {
				let src = document.querySelector('.section--coupdecoeur a').dataset.srcMobile;
				if (windowWidth > 810) {
					src = document.querySelector('.section--coupdecoeur a').dataset.srcDesktop
				}
				document.querySelector('.section--coupdecoeur img').setAttribute('src', src)
			}
		}

		/// Conseiller
		function launchConseillerSlider() {
			new Glide('[data-slider="conseiller"]', {
					type: 'carousel',
					autoplay: false,
					gap: 0,
					perView: 3
				})
				.mount();
		}

		if (!helpers.isTouchDevice()) {
			const conseillers = setTimeout(launchConseillerSlider, 2000);
		}
	}
}

module.exports = home;